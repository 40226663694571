import axios from './base';

function get(url, params) {
  return axios.get(url, {
    params,
  });
}

function post(url, data) {
  return axios.request({
    url,
    method: 'post',
    data,
  });
}

function del(url, data) {
  return axios.request({
    url,
    method: 'delete',
    data,
  });
}

function multi(requests) {
  return axios.all(requests);
}

export default {
  get,
  post,
  del,
  multi,
};
