import request from './request';

const scroll = () => request.get('api/scroll/home');

const projectTypes = () => request.get('api/product/types');

const projects = (type, page) => request.get(`api/product/list/${type}?page=${page}`);

const projectDetail = (id) => request.get(`api/product/${id}`);

const awards = (page) => request.get(`api/default/awards?page=${page}`);

const news = (page) => request.get(`api/news/list?page=${page}`);

const newsDetail = (id) => request.get(`api/news/${id}`);

const jobs = () => request.get('api/job/list');

const members = () => request.get('api/default/members');

export {
  scroll,
  projectTypes,
  projects,
  projectDetail,
  awards,
  news,
  newsDetail,
  jobs,
  members,
};
