import axios from 'axios';
import qs from 'qs';
import { Message } from 'element-ui';
import bowser from 'bowser';
import router from 'vue-router';

const ua = navigator.userAgent.toLowerCase();
const isInWx = ua.match(/micromessenger/i) === 'micromessenger';

const baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.baseURL = baseURL;

const service = axios.create({
  baseURL,
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    const tempConfig = config;
    // if (store.state.user.token) {
    //   tempConfig.headers.Authorization = `Bearer ${store.state.user.token}`;
    // }
    tempConfig.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    if (tempConfig.method === 'post') {
      tempConfig.data = qs.stringify(tempConfig.data);
    }
    // 当前设备
    tempConfig.headers.Dev = bowser.mobile ? 'wap' : 'web';
    tempConfig.headers['In-Wei-xin'] = isInWx;
    return tempConfig;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.status !== 0) {
      const message = res.error instanceof Array ? res.error.join('\r\n') : res.error;
      if (res.status === 401) {
        // 去登陆
        router.push({
          path: '/login',
        });
      } else {
        Message({
          message,
          type: 'error',
          duration: 3 * 1000,
        });
      }
      return Promise.reject(new Error(message) || 'Error');
    }
    return res;
  },
  (error) => {
    console.error(`err${error}`);
    Message({
      message: error.message,
      type: 'error',
      duration: 3 * 1000,
    });
    return Promise.reject(error);
  },
);

export default service;
