// 封装localstorage和sessionstorage

const isServer = typeof window === "undefined";

const store = {
  version: "1.0.0",
  storage: !isServer ? window.localStorage : null,
  session: {
    storage: !isServer ? window.sessionStorage : null
  }
};

function serialize(val) {
  return JSON.stringify(val);
}

function unserialize(val) {
  if (typeof val !== "string") {
    return undefined;
  }
  try {
    return JSON.parse(val);
  } catch (e) {
    return val || undefined;
  }
}

const api = {
  set(key, val) {
    if (this.disabled) {
      return false;
    }
    if (val === undefined) {
      return this.remove(key);
    }
    this.storage.setItem(key, serialize(val));
    return val;
  },
  get(key, def) {
    if (this.disabled) {
      return false;
    }
    const val = unserialize(this.storage.getItem(key));
    return val === undefined ? def : val;
  },
  has(key) {
    return this.get(key) !== undefined;
  },
  remove(key) {
    if (this.disabled) {
      return;
    }
    this.storage.removeItem(key);
  },
  clear() {
    if (this.disabled) {
      return;
    }
    this.storage.clear();
  },
  getAll() {
    if (this.disabled) {
      return null;
    }
    const ret = {};
    this.forEach((key, val) => {
      ret[key] = val;
    });
    return ret;
  },
  forEach(callback) {
    if (this.disabled) {
      return;
    }
    for (let i = 0; i < this.storage.length; i += 1) {
      const key = this.storage.key(i);
      callback(key, this.get(key));
    }
  }
};

Object.assign(store, api);

Object.assign(store.session, api);

try {
  const testKey = "__storejs__";
  store.set(testKey, testKey);
  if (store.get(testKey) !== testKey) {
    store.disabled = true;
  }
  store.remove(testKey);
} catch (e) {
  store.disabled = true;
}

export default store;
