import Vue from 'vue';
import Vuex from 'vuex';

import {
  projectTypes,
  members,
} from '@/api/data';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    firstEnterWebSite: true,
    routerSwitchAnimating: false,
    subMenusOpen: false,
    productTypes: [],
    members: [],
  },
  mutations: {
    WEBSITE_ENTERED(state) {
      state.firstEnterWebSite = false;
    },
    ANIMATION_START(state) {
      state.routerSwitchAnimating = true;
    },
    ANIMATION_END(state) {
      state.routerSwitchAnimating = false;
    },
    OPEN_SUB_MENU(state) {
      state.subMenusOpen = true;
    },
    CLOSE_SUB_MENU(state) {
      state.subMenusOpen = false;
    },
    SET_PRODUCT_TYPES(state, val) {
      state.productTypes = val;
    },
    SET_MEMBERS(state, val) {
      state.members = val;
    },
  },
  actions: {
    getProductTypes({ commit }) {
      return new Promise((resolve, reject) => {
        projectTypes().then((res) => {
          const { data } = res;
          commit('SET_PRODUCT_TYPES', data);
          resolve(data);
        }).catch(() => {
          reject();
        });
      });
    },
    getMembers({ commit }) {
      return new Promise((resolve, reject) => {
        members().then((res) => {
          const list = res.data;
          commit('SET_MEMBERS', list);
          resolve(list);
        }).catch(() => {
          reject();
        });
      });
    },
  },
  modules: {
  },
});
