import Vue from 'vue';

import bowser from 'bowser';

let ticking = false;
const windowHeight = window.innerHeight;
const rate = bowser.mobile ? 1 : 0.95;
const bottomHeight = windowHeight * rate;

// 自动撑高度
function heightFullScreen(wrapper) {
  const tempWrap = wrapper;
  const height = window.innerHeight;
  Vue.nextTick(() => {
    const currentHeight = document.body.getBoundingClientRect().height;
    const wrapperHeight = currentHeight < height ? height - currentHeight : 500;
    tempWrap.style.cssText = `height: ${tempWrap.getBoundingClientRect()
      .height + wrapperHeight}px`;
  });
}
// 判断是否ie
function IEVersion() {
  const { userAgent } = navigator; // 取得浏览器的userAgent字符串
  // 判断是否IE<11浏览器
  const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1;
  const isEdge = userAgent.indexOf('Edge') > -1 && !isIE; // 判断是否IE的Edge浏览器
  const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
  if (isIE) {
    const reIE = new RegExp('MSIE (\\d+\\.\\d+);');
    reIE.test(userAgent);
    const fIEVersion = parseFloat(RegExp.$1);
    if (fIEVersion === 7) {
      return 7;
    } if (fIEVersion === 8) {
      return 8;
    } if (fIEVersion === 9) {
      return 9;
    } if (fIEVersion === 10) {
      return 10;
    }
    return 6;// IE版本<=7
  } if (isEdge) {
    // return 'edge';// edge
    return -1;
  } if (isIE11) {
    return 11; // IE11
  }
  return -1;// 不是ie浏览器
}

function handleScroll() {
  const scolls = [...document.querySelectorAll('.scrollObj')].filter((item) => {
    const isAnimated = item.classList.contains('animated');
    const { top, height } = item.getBoundingClientRect();
    const isInView = top <= bottomHeight + 100 && top > 0;
    const isImmediate = Number(item.dataset.immediate) === 1;
    if (isAnimated && !isInView && top - height > 0) {
      // item.classList.remove("animated");
    }
    return (!isAnimated && isInView) || isImmediate;
  });

  function handleShow(item, k) {
    const { effect } = item.dataset;
    switch (effect) {
      case 'delay':
        if (item.getBoundingClientRect().top <= window.innerHeight * 0.5) {
          item.classList.add('animated');
        }
        break;
      default:
        setTimeout(() => {
          item.classList.add('animated');
        }, k * 100);
        break;
    }
  }

  scolls.forEach((item, key) => {
    handleShow(item, key);
  });

  if (document.querySelector('#nmb')) {
    if (document.documentElement.scrollTop > 0) {
      if ((document.documentElement.scrollTop + document.documentElement.clientHeight) >= (document.body.clientHeight * 0.4)) {
        document.querySelector('#nmb').classList.remove('hidden');
      } else {
        document.querySelector('#nmb').classList.add('hidden');
      }
    } else {
      document.querySelector('#nmb').classList.add('nmb');
    }
  }

  const ab = document.querySelector('#about__cooperation-container');
  if (ab) {
    const bcr = ab.getBoundingClientRect();
    if (bcr.top < windowHeight * 0.5 && bcr.top > 0) {
      document.body.classList.remove('white');
    } else {
      document.body.classList.add('white');
    }
  }

  const $award = document.querySelector('.awardChangeBg');
  if ($award && !bowser.mobile) {
    const $awardCompare = document.querySelector('.page-awards__logos-container');
    const bg = ($award.getBoundingClientRect().top <= windowHeight && $awardCompare.getBoundingClientRect().bottom <= 0) ? '#f5f5f5' : '#fff';
    document.querySelector('.page-awards-container').style.cssText = `background-color:${bg}`;
    document.body.style.backgroundColor = bg;
  }

  const $projectDetailBack = document.querySelector('#mobileBack');

  if ($projectDetailBack) {
    const srcollTop = document.body.scrollTop || document.documentElement.scrollTop;
    // document.getElementById('nmb').innerText = 'show';
    // const p = document.createElement('p');
    // p.innerText = srcollTop;
    // document.getElementById('nmb').appendChild(p);
    if (srcollTop > 0 && (srcollTop + windowHeight) >= document.body.offsetHeight * 0.75) {
      $projectDetailBack.classList.add('actived');
    } else {
      $projectDetailBack.classList.remove('actived');
    }
  }

  ticking = false;
}

function onScroll() {
  if (!ticking) {
    requestAnimationFrame(handleScroll);
    ticking = true;
  }
}

function pageInit() {
  onScroll();
  window.addEventListener('scroll', onScroll);
}
export default {
  install: () => {
    Vue.prototype.heightFullScreen = (wrapper) => heightFullScreen(wrapper);
    Vue.prototype.IEVersion = () => IEVersion();
    Vue.prototype.pageInit = () => pageInit();
  },
};
